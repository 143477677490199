import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import imgun from "../assets/images/unsubscribe.svg"

const unsubscribe = () => {
  return (
    <Layout>
      <SEO title="Unsubscribe Request" />
      <div className="page-unsubscribe">
        <div className="wrap-unsb">
          <div className="mt-4">
            <h2 className="title-text">Unsubscribe from EOI Report</h2>
          </div>
          <img
            style={{ height: "266px", width: "266px", display: "block" }}
            className="unsub"
            alt="unsubscribe"
            src={imgun}
          />

          <div className="mt-5 d-flex justify-content-center">
            <h6>We're sorry to see you go!</h6>
          </div>
          <div className="subt">
            Your unsubscribe request has been received and our admins will
            attend to it shortly.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default unsubscribe
